<template>

 <!-- Sidebar -->
        <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar" :class="{ toggled: sidebarProp }">

            <!-- Sidebar - Brand -->
            <a class="sidebar-brand d-flex align-items-center justify-content-center" href="/">
                <div class="sidebar-brand-icon rotate-n-15">
                    <i class="fas fa-fish"></i>
                </div>
                <div class="sidebar-brand-text mx-3">Pescari</div>
            </a>

            <!-- Divider -->
            <hr class="sidebar-divider my-0">        

         
            <li class="nav-item">
               <router-link to="/concursuri" class="nav-link collapsed">
                  <i class="fas fa-fw fa-calendar-alt"></i>
                  <span>Concursuri</span>
                </router-link>
            </li>
             <li class="nav-item"  v-if=' $route.name == "edit-concurs" || $route.name == "edit-echipa" '>
               <router-link :to="{ name:'edit-concurs', params: { id: $route.params.idConcurs} }" class="nav-link collapsed">
                  <i class="fas fa-fw fa-calendar-alt"></i>
                  <span>Echipe</span>
                </router-link>
            </li>




            <!-- Divider -->
            <hr class="sidebar-divider">

            <!-- Heading -->
            <!-- <div class="sidebar-heading">
                Addons
            </div> -->

            <!-- <li class="nav-item">
                <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsePages"
                    aria-expanded="true" aria-controls="collapsePages">
                    <i class="fas fa-fw fa-folder"></i>
                    <span>Pages</span>
                </a>
                <div id="collapsePages" class="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
                    <div class="bg-white py-2 collapse-inner rounded">
                        <h6 class="collapse-header">Login Screens:</h6>
                        <a class="collapse-item" href="login.html">Login</a>
                        <a class="collapse-item" href="register.html">Register</a>
                        <a class="collapse-item" href="forgot-password.html">Forgot Password</a>
                        <div class="collapse-divider"></div>
                        <h6 class="collapse-header">Other Pages:</h6>
                        <a class="collapse-item" href="404.html">404 Page</a>
                        <a class="collapse-item" href="blank.html">Blank Page</a>
                    </div>
                </div>
            </li> -->
          

            <!-- Sidebar Toggler (Sidebar) -->
            <div class="text-center d-none d-md-inline">
                <button class="rounded-circle border-0" id="sidebarToggle" @click="clickSidebarToggle"></button>
            </div>

        </ul>
        <!-- End of Sidebar -->

</template>
<script>


export default {
  name: "sidebar",
  props: ["sidebarToggled"],
  emits: ["toggleStatus"],
  data() {
    return {
      sidebarProp: this.sidebarToggled
    };
  },
   watch:{
    sidebarToggled: function(data){
      this.sidebarProp = data;
      console.log("sidebar "+this.sidebarProp);
    }
  },
  methods:{
    clickSidebarToggle(){
      this.sidebarProp = !this.sidebarProp;
      this.$emit("toggleStatus",this.sidebarProp);
    }
  }
};
</script>
<style scoped>
</style>