<template>

  <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

      <button id="sidebarToggleTop"  @click="clickSidebarToggle" class="btn btn-link d-md-none rounded-circle mr-3">
          <i class="fa fa-bars"></i>
      </button>

      <ul class="navbar-nav ml-auto">
          
          <div class="topbar-divider d-none d-sm-block"></div>

          <li class="nav-item dropdown no-arrow">
              <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <span class="mr-2 d-none d-lg-inline text-gray-600 small">Lazy Bird</span>
                  <img class="img-profile rounded-circle"
                      src="../img/avatar.jpg">
              </a>
              <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                  aria-labelledby="userDropdown">
                  <a class="dropdown-item" href="#">
                      <i class="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                      Profil
                  </a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" href="#" data-toggle="modal" data-target="#logoutModal">
                      <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                      Logout
                  </a>
              </div>
          </li>
      </ul>
  </nav>
</template>
<script>


export default {
  name: "topnav",
  props: ["sidebarToggled"],
  emits: ["toggleStatus"],
  data() {
    return {
      data: null,
      sidebarProp: this.sidebarToggled
    };
  },
  watch:{
    sidebarToggled: function(data){
      this.sidebarProp = data;
      console.log("top nav "+this.sidebarProp);

    }
  },
  methods:{
    clickSidebarToggle(){
      this.sidebarProp = !this.sidebarProp;
      this.$emit("toggleStatus",this.sidebarProp);
    }
  }
};
</script>
<style scoped>
</style>