import { createWebHistory, createRouter } from "vue-router";
const routes =  [
  {
    path: "/",
    alias: "/concursuri",
    name: "concursuri",
    component: () => import("./components/ConcursuriList")
  },
  {
    path: "/vezi-concurs/:idConcurs",
    name: "vezi-concurs",
    component: () => import("./components/ConcursPublic")
  },
  {
    path: "/edit-concurs/:idConcurs",
    name: "edit-concurs",
    component: () => import("./components/ConcursSingle")
  },
   {
    path: "/edit-concurs/:idConcurs/echipa/:idEchipa",
    name: "edit-echipa",
    component: () => import("./components/EchipeSingle")
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
});
export default router; 