import { createApp } from 'vue'
import App from './App.vue'

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

import './assets/style.css'
import '/demo_theme/vendor/fontawesome-free/css/all.min.css'
import '/demo_theme/css/sb-admin-2.min.css'

import router from './router'

createApp(App).use(router).mount('#app')