<template>
  <div id="wrapper">

      <sidebar :sidebarToggled="sidebarToggled" @toggleStatus="toggleStatus" />

       <!-- Content Wrapper -->
        <div id="content-wrapper" class="d-flex flex-column">
            <!-- Main Content -->
            <div id="content">

            <!-- navbar -->

              <div class="container-fluid">

                  <topnav :sidebarToggled="sidebarToggled" @toggleStatus="toggleStatus" />
                 <router-view />
              </div>
            </div>
            <!-- Close Main Content -->
        </div><!-- close content wrapper -->

  </div>

</template>

<script>

import Sidebar from "./components/Sidebar";
import Topnav from "./components/Topnav";

export default {
  name: "app",
  components: { Sidebar, Topnav },
  data(){
    return{
      sidebarToggled: true,
    }
  },
  methods:{
    toggleStatus(toggle){
      this.sidebarToggled = toggle;
    }
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,700;0,900;1,600;1,900&display=swap');
.margin-right-btn{
  margin-right:15px;
}
.cursor-pointer-default{
  cursor:pointer;
}
@media screen and (max-width:900px){
  body .container-fluid .container-fluid{
    padding-left:0px;
    padding-right:0px;
    overflow:scroll;
  }
}
html{
  background-color: #f8f9fc;
}
html body{
  color:#5a5c69;
}
.btn{
  flex-shrink: 0;
}
</style>